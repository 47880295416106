const baseURL = 'subscriptions'

export default {
  /**
   * Get primary subscription link.
   *
   * @returns Promise
   */
  async getPrimarySubscriptionLink () {
    const response = await axios.get(`${baseURL}/primary-subscription-link`)

    return response.data.link
  },

  /**
   * Get receipts.
   *
   * @returns Promise
   */
  async getReceipts () {
    const response = await axios.get(`${baseURL}/receipts`)

    return response.data.data
  },

  /**
   * Get lifetime deals left.
   *
   * @returns int
   */
  getLifetimeDealsLeft () {
    return axios.get(`/public/lifetime-deals-left`, {
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    }).then(response => response.data.left)
  },

  async getClientSecret () {
    const response = await axios.get('/spark/token')
    return response.data.clientSecret
  },

  async calculateTax (planId) {
    const response = await axios.post('/subscriptions/calculate-tax', { planId })
    return response.data
  },

  async sparkSubscription (body) {
    return axios.post('/subscriptions/new', body)
  },

  async getSubscriptionPlans (type) {
    const response = await axios.get(`${baseURL}/subscription-plans?type=${type}`)
    return response.data
  },

  async applyCoupon (coupon, billableType) {
    return axios.put('/subscription/coupon', { 'coupon': coupon, billableType: billableType }).catch(error => {
      return error.response
    })
  },

  subscribeToFree (plan) {
    return axios.post(`${baseURL}/subscribe-to-free`, plan)
  },

  getInvoices () {
    return axios.get(`${baseURL}/invoices`)
  },

  async getStripeSessionUrl (planId) {
    const response = await axios.get(`${baseURL}/stripe-session-url`, { params: { planId } })
    return response.data
  },

  async getStripeAddonSessionUrl (addons) {
    const response = await axios.get(`${baseURL}/stripe-addon-session-url`, { params: { addons } })
    return response.data
  },

  async getAddonsItems () {
    const response = await axios.get(`${baseURL}/addons`)
    return response.data
  },

  async checkoutSuccess (session_id) {
    const response = await axios.post('/subscriptions/checkout/success', { session_id })
    return response.data
  }

}
