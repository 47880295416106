<template>
  <div class="Subscription">
    <div class="card">
      <div class="card-body">
        Success!
        <br>
        These are your new limits:
        <br>
        Monitors: {{ user.limits.monitors }}
        <br>
        Status Page Subscribers: {{ user.limits.status_page_subscribers }}
        <br>
        Teammates: {{ user.limits.teammates }}
        <br>
        SMS/Voice Calls Alerts: {{ user.limits.sms_calls }}
        <br>
        Notify Only Seats: {{ user.limits.notify_only_seats }} (not implemented yet)
      </div>
    </div>
  </div>
</template>

<script>

import subscriptionsApi from '@/api/subscriptionsApi'

export default {
  components: {
  },

  metaInfo: {
    title: 'Subscription'
  },

  created () {
    // Extract the session ID from the URL
    const urlParams = new URLSearchParams(window.location.search)
    this.sessionId = urlParams.get('session_id')

    if (this.sessionId) {
      // Send the session ID to the backend for processing
      this.processSubscriptionUpdate(this.sessionId)
    }
  },

  methods: {
    async processSubscriptionUpdate (sessionId) {
      try {
        await subscriptionsApi.checkoutSuccess(sessionId)
      } catch (error) {
        console.error('Something went wrong')
      }
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.Subscription {

}
</style>
